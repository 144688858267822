import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import '../../node_modules/@mdi/font/css/materialdesignicons.min.css';
import 'styles/main.scss';

import Footer from 'components/Footer.js';

import OGImage from 'assets/img/og-image.png';

const Layout = props => {
  const description =
    'Caitlin Wang is a design-minded software engineer based in Brooklyn, NY. She currently builds thoughtful websites and products at Upstatement';

  return (
    <div>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <title>Caitlin Wang</title>
        <meta name="title" content="Caitlin Wang" />
        <meta name="description" content={description} />
        <meta
          name="keywords"
          content="web development, software engineer, creative technologist, developer"></meta>
        <meta name="theme-color" content="#FFFFFA" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://caitlinwang.com/" />
        <meta property="og:title" content="Caitlin Wang" />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={OGImage} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://caitlinwang.com/" />
        <meta property="twitter:title" content="Caitlin Wang" />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={OGImage} />
      </Helmet>

      <main>{props.children}</main>

      {!props.isPhotoboard && <Footer />}
    </div>
  );
};

Layout.propTypes = {
  isPhotoboard: PropTypes.bool,
  children: PropTypes.element,
};

Layout.defaultProps = {
  isPhotoboard: false,
};

export default Layout;
